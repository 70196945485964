












































































import { Component, Vue } from 'vue-property-decorator';
import { ApprovalQualityContent } from '@/types/neuhaus-website/cms/pages/approval-quality';
import axios, { APIResponse } from '@/plugins/axios';

/**
 * CMSPageApprovalQuality Component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-page': () => import('@/components/cms/pages/CMSPage.vue'),
        'cms-component-unordered-list': () => import('@/components/cms/components/CMSComponentUnorderedList.vue'),
        'cms-component-file-download': () => import('@/components/cms/components/CMSComponentFileDownload.vue'),
        'cms-component-image': () => import('@/components/cms/components/CMSComponentImage.vue')
    }
})
export default class CMSPageApprovalQuality extends Vue {
    // variable with page content
    private pageContent: ApprovalQualityContent = {
        id: -1,
        title: 'N/A',
        firstText: 'N/A',
        procedureSection: {
            title: 'N/A',
            procedures: [],
            texts: []
        },
        certificateSection: {
            title: 'N/A',
            certificates: []
        },
        rightEdgeImage: {
            id: -1,
            src: '',
            obj: undefined
        },
        bottomImage: {
            id: -1,
            src: '',
            obj: undefined
        } 
    };

    /**
     * @returns Returns API URL + given url
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private getAbsoluteImageUrl(url: string): string {
        return process.env.VUE_APP_API_URL + url;
    }

    /**
     * Function will be executed on component load
     * fetches page content
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        this.pageContent = await this.fetchPageContent();
    }

    /**
     * Fetches page content from API
     * 
     * @returns ApprovalQualityContent
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchPageContent(): Promise<ApprovalQualityContent> {
        try {
            const response = await axios.get<APIResponse<ApprovalQualityContent>>('/cms/pages/approvalquality')

            if (response.data.status === 'error' || !response.data.data) {
                throw null;
            }

            return response.data.data;
        } catch (err) {
            return this.pageContent;
        }
    }
}
